// Primary Colors
$background: #121212;
$surface: #1E1E1E;

// Accent Colors
$primary-accent: #BB86FC;
$secondary-accent: #03DAC6;

// Text Colors
$primary-text: #FFFFFF;
$secondary-text: #B3B3B3;
$hint-text: #828282;

// Additional Colors
$error: #CF6679;
$success: #03C03C;
$warning: #FFCC00;

$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.5);